import deburr from "lodash/deburr";
import { inflate } from "pako";

export function shortenString(sentence?: string | null, n = 15) {
  if (!sentence) return;

  if (sentence.length > n) {
    return `${sentence.substring(0, n)}...`;
  } else {
    return sentence;
  }
}

/**
 * Use this function to convert a gzipped string into a decompressed string
 * This is not a replacement for content encoding header.
 * @param gzippedString
 */
export function getUngzippedString(gzippedString: string) {
  // Decode base64 (convert ascii to binary)
  const binaryData = window.atob(gzippedString);

  // Convert binary string to number array containing character codes
  const binData = Uint8Array.from(
    binaryData.split("").map((x) => x.charCodeAt(0)),
  );

  // Pako magic
  const decompressedData = inflate(binData);

  // Convert gunzipped byteArray back to ascii string:
  return String.fromCharCode.apply(null, Array.from(decompressedData));
}

export function searchIgnoringAccents(text: string, searchTerm: string) {
  return deburr(text).toLowerCase().includes(deburr(searchTerm).toLowerCase());
}
