import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import axios from "axios";
import {
  type AdminBidDto,
  AdminProductsApi,
  type AdminProductSkuDto,
  type AdminProductsSkuIdPatchRequest,
  type CreateProductRequest,
  type UpdateProductDisplayPriceRequest,
  type UpdateProductPriceRequest,
} from "mws-catalog-ts-sdk";
import type { Ref } from "vue";
import { useToast } from "vue-toastification";

import type { Guid, UseQueryOptionsOmitQueryKey } from "@/common/types";
import type { ProductSku } from "@/products/types";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;
const adminProductsApi = new AdminProductsApi(undefined, catalogApiUrl);

export const useGetProductListingsListQuery = (
  searchTerm: Ref,
  pageSize: Ref,
  offset: Ref,
  filters: Ref<{
    platformKey: string;
    sortColumn?: string;
    sortDirection?: string;
    salesMethodFilter?: string;
    productId?: string;
  }>,
) => {
  const queryKey = ["products", { searchTerm, offset, pageSize, filters }];

  const queryFn = async () => {
    return (
      await adminProductsApi.adminProductsGet({
        xPlatformKey: filters?.value.platformKey,
        searchTerm: searchTerm.value,
        offset: offset.value,
        size: pageSize.value,
        sortColumn: filters?.value.sortColumn,
        sortDirection: filters?.value.sortDirection,
        salesMethodFilter: filters?.value.salesMethodFilter,
        productId: filters?.value.productId,
      })
    ).data;
  };

  const options = { keepPreviousData: true };

  return useQuery({ queryKey, queryFn, ...options });
};

export const useGetProductListingByIdQuery = (
  productListingId: Ref<Guid | undefined>,
  options?: UseQueryOptionsOmitQueryKey<AdminProductSkuDto>,
) => {
  const queryKey = ["products", productListingId];

  const queryFn = async () => {
    return (
      await adminProductsApi.adminProductsSkuIdGet({
        skuId: productListingId.value || "",
      })
    ).data;
  };

  return useQuery({ queryKey, queryFn, ...options });
};

export const usePatchProductListingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      skuId: Guid;
      adminProductsSkuIdPatchRequest: AdminProductsSkuIdPatchRequest;
    }) => {
      const response = await adminProductsApi.adminProductsSkuIdPatch(request);

      return response.data;
    },

    onSuccess: (data: AdminProductSkuDto) => {
      queryClient.setQueryData(["products", data.id], data);
    },
  });
};

export const usePatchProductListingPriceMutation = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: async (request: {
      skuId: Guid;
      updateProductPriceRequest: UpdateProductPriceRequest;
    }) => {
      const response = await adminProductsApi.adminProductsSkuIdPricesPatch({
        skuId: request.skuId,
        adminProductsSkuIdPricesPatchRequest: request.updateProductPriceRequest,
      });

      return response.data;
    },

    onSuccess: () => {
      toast.success("Prices Updated Succesfully!");
    },
  });
};

export const usePatchProductListingDisplayPriceMutation = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: async (request: {
      skuId: Guid;
      updateProductDisplayPriceRequest: UpdateProductDisplayPriceRequest;
    }) => {
      const response =
        await adminProductsApi.adminProductsSkuIdDisplayPricesPatch({
          skuId: request.skuId,
          adminProductsSkuIdDisplayPricesPatchRequest:
            request.updateProductDisplayPriceRequest,
        });

      return response.data;
    },

    onSuccess: () => {
      toast.success("Display Prices Updated Succesfully!");
    },
  });
};

export const useCreateProductListingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: CreateProductRequest) => {
      const response = await adminProductsApi.adminProductsPost({
        adminProductsPostRequest: request,
      });

      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

export const useUpdateProductListingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (productListing: Partial<ProductSku>) => {
      const response = await axios.patch(
        `${catalogApiUrl}/admin/products/${productListing.id}/`,
        productListing,
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userOrders"] });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
};

export const useGetProductListingBidsQuery = (
  productId: Ref<Guid>,
  options?: UseQueryOptionsOmitQueryKey<AdminBidDto[]>,
) => {
  const queryKey = ["products", productId, "bids"];

  const queryFn = async () => {
    return (
      await adminProductsApi.adminProductsSkuIdBidsGet({
        skuId: productId.value,
      })
    ).data;
  };

  return useQuery({ queryKey, queryFn, ...options });
};
