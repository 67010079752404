import { useMutation } from "@tanstack/vue-query";
import axios, { type AxiosError } from "axios";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;

export const useGenerateUploadUrlMutation = (
  onSuccess: (data: { key: string; preSignedUrl: string }) => void,
  onError: (error: AxiosError) => void,
) => {
  return useMutation({
    mutationFn: async (fileName: string) => {
      const response = await axios.post(`${catalogApiUrl}/admin/uploads`, {
        fileName,
      });

      return response.data;
    },
    onSuccess,
    onError,
  });
};
