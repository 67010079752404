<script setup lang="ts">
import type { FormKitFrameworkContext } from "@formkit/core";
import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import axios from "axios";
import mime from "mime-lite";
import { computed, onMounted, ref, type Ref, watch } from "vue";

import { useGenerateUploadUrlMutation } from "@/common/api/queries";
import { downloadExternalFile } from "@/common/utils/downloadExternalFile";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

const file: Ref<File | null> = ref(null);
const fileInput: Ref<HTMLInputElement | null> = ref(null);
const existingFileUrl = computed(() => props.context.node.value as string);

const filePreview = computed(() =>
  file.value ? URL.createObjectURL(file.value) : existingFileUrl.value || null,
);
const onImagePreviewClick = () => {
  props.context.node.emit("imagePreviewClicked", {
    imagePreviewUrl: filePreview.value,
  });
};

const { mutate: uploadFile, isPending } = useGenerateUploadUrlMutation(
  async (data) => {
    // Delete auth header to not confuse AWS when uploading to S3.
    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    const fileValue = (file as Ref<File>).value;

    await instance.put(data.preSignedUrl, fileValue, {
      headers: {
        "Content-Type": fileValue.type,
      },
    });

    props.context.node.input(data.key);
  },
  (error) =>
    props.context.node.setErrors(
      `Something went wrong uploading your file: "${error.message}"`,
    ),
);

function onOpenDialog() {
  fileInput.value?.click();
}

function onDrop(event: DragEvent) {
  if (event.dataTransfer?.files && !!fileInput.value) {
    fileInput.value.files = event.dataTransfer?.files;
    fileInput.value.dispatchEvent(new Event("change"));
  }
}

function onChangeFile(payload: Event) {
  const target = payload.target as HTMLInputElement;
  file.value = target?.files?.item(0) || null;

  if (!file.value) {
    props.context.node.input(null);

    return;
  }

  uploadFile(file.value.name);
}

function onRemoveFile() {
  file.value = null;
  props.context.node.input(null);
  (fileInput.value as HTMLInputElement).value = "";
}

function downloadFile(uri: string, filename: string) {
  downloadExternalFile({ uri, filename });
}

const initialFile = computed(
  () => props.context.initialFile as File | undefined,
);
onMounted(() => {
  watch(
    initialFile,
    (newVal) => {
      if (!newVal || !fileInput.value) {
        return;
      }

      const dT = new DataTransfer();
      dT.items.add(newVal);

      fileInput.value.files = dT.files;
      fileInput.value.dispatchEvent(new Event("change"));
    },
    { immediate: true },
  );
});
</script>

<template>
  <input
    id="file"
    ref="fileInput"
    type="file"
    hidden
    v-bind="context.attrs"
    @change="onChangeFile"
  />

  <div
    class="flex flex-col align-center my-1 rounded-md bg-gray-50"
    :class="{
      'hover:bg-gray-100 border border-dashed border-indigo-600':
        !context.node.value,
      'h-32 p-4': !context.previewFull,
      'p-2': context.previewFull,
    }"
  >
    <div
      v-if="isPending"
      class="flex h-24 w-full animate-ping items-center justify-center text-center text-xl"
    >
      ...
    </div>
    <div
      v-else-if="context.node.value"
      class="flex flex-1 flex-col justify-center"
      :class="{ 'w-full': context.previewFull }"
    >
      <div class="flex items-center justify-between">
        <div
          v-if="
            filePreview &&
            (mime.getType(context.node.value as string) === 'image/jpeg' ||
              mime.getType(context.node.value as string) === 'image/png')
          "
          class="grow"
        >
          <img
            :src="filePreview"
            :class="`object-scale-down mr-4 ${
              context.previewFull ? 'h-80 w-full' : 'h-24 w-full'
            }`"
            @click="onImagePreviewClick"
          />
        </div>
        <div
          v-else-if="
            filePreview &&
            mime.getType(context.node.value as string) === 'application/pdf'
          "
        >
          <embed
            :src="filePreview"
            :class="`mr-4 ${context.previewFull ? 'h-80 w-full' : 'h-24 w-28'}`"
            type="application/pdf"
          />
        </div>
        <div
          v-else-if="
            filePreview &&
            mime.getType(context.node.value as string)?.startsWith('video/')
          "
        >
          <video
            :class="`object-scale-down mr-4 ${
              context.previewFull ? 'h-80 w-full' : 'h-24 w-28'
            }`"
            autoplay
            muted
          >
            <source :src="context.node.value as string" />
          </video>
        </div>

        <div
          v-if="!context.hideName && !context.previewFull"
          class="grow"
        >
          {{ (context.node.value as string).split("/").pop() }}
        </div>

        <div
          v-if="!context.hideSideIcons && !context.previewFull"
          class="flex flex-col gap-4 pl-2"
        >
          <XMarkIcon
            class="size-6 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-100 rounded cursor-pointer"
            @click="onRemoveFile"
          />
          <ArrowDownTrayIcon
            class="size-6 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-100 rounded cursor-pointer"
            @click="
              downloadFile(
                context.node.value as string,
                (context.node.value as string).split('/').pop() as string,
              )
            "
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="h-24 flex justify-center items-center cursor-pointer"
      @click="onOpenDialog"
      @drop.prevent="onDrop"
      @dragenter.prevent
      @dragover.prevent
    >
      <div clas="text-indigo-600 text-sm mb-2">
        {{ "Drop a file or " }}
        <span>click here</span>
        {{ " to upload" }}
      </div>
    </div>
  </div>
</template>
