export const productRoutes = [
  {
    path: "/products",
    name: "ProductListBase",
    component: () => import("./views/ProductListBase.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
    children: [
      {
        path: "",
        name: "ProductList.Table",
        component: () => import("./views/ProductsListPage.vue"),
      },
      {
        path: "scan",
        name: "ProductList.Scan",
        component: () => import("./views/ProductsScanPage.vue"),
      },
    ],
  },
  {
    path: "/products/create",
    name: "ProductCreatePage",
    component: () => import("./views/ProductCreatePage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/products/:productId",
    name: "ProductDetailsPage",
    component: () => import("@/products/products/views/ProductDetailsPage.vue"),
    children: [
      {
        path: "",
        name: "ProductDetails.Overview",
        component: () =>
          import("@/products/products/views/ProductDetailsOverview.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "listings",
        name: "ProductDetails.Listings",
        component: () =>
          import("@/products/products/views/ProductDetailsListings.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "notes",
        name: "ProductDetails.Notes",
        component: () =>
          import("@/products/products/views/ProductNotesPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
];
