import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import {
  type AdminMediaContentRequest,
  AdminProductsApi,
  type AdminProductsApiV2AdminProductsProductIdInventoryLocationPutRequest,
  type AdminProductsApiV2AdminProductsProductIdLinkFramedProductPatchRequest,
  type V2AdminProductsPostRequest,
  type V2AdminProductsProductIdPatchRequest,
} from "mws-ts-v2-sdk";
import { FramingType } from "mws-ts-v2-sdk";
import type { MaybeRefOrGetter, Ref } from "vue";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;
const adminProductsApi = new AdminProductsApi(undefined, catalogApiUrl);

export const useCreateProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: V2AdminProductsPostRequest) => {
      const response = await adminProductsApi.v2AdminProductsPost({
        v2AdminProductsPostRequest: request,
      });

      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

export const useGetProductsListQuery = (
  searchTerm?: Ref,
  pageSize?: Ref,
  offset?: Ref,
  filters?: Ref<{
    teamId: string;
    athleteId: string;
    sortColumn?: string;
    sortDirection?: string;
    hasStock?: boolean;
    hasListings?: boolean;
    framingType?: FramingType;
  }>,
) => {
  const queryKey = ["products", { searchTerm, offset, pageSize, filters }];

  const queryFn = async () => {
    return (
      await adminProductsApi.v2AdminProductsGet({
        searchTerm: searchTerm?.value,
        offset: offset?.value,
        size: pageSize?.value,
        sortColumn: filters?.value.sortColumn,
        sortDirection: filters?.value.sortDirection,
        athleteId: filters?.value.athleteId,
        teamId: filters?.value.teamId,
        hasStock: filters?.value.hasStock,
        hasListings: filters?.value.hasListings,
        framingType: filters?.value.framingType,
      })
    ).data;
  };

  const options = { keepPreviousData: true };

  return useQuery({ queryKey, queryFn, ...options });
};

export const useGetProductQuery = (
  productId: Ref<string | undefined>,
  options?: {
    enabled?: MaybeRefOrGetter<boolean | undefined>;
  },
) => {
  const key = ["products", productId];

  const query = async () => {
    if (!productId.value) {
      throw new Error("productId is required");
    }

    return (
      await adminProductsApi.v2AdminProductsIdGet({
        id: productId.value,
      })
    ).data;
  };

  return useQuery({
    queryKey: key,
    queryFn: query,
    enabled: options?.enabled,
  });
};

export const usePatchProductMutation = () => {
  return useMutation({
    mutationFn: async (request: {
      productId: string;
      v2AdminProductsProductIdPatchRequest: V2AdminProductsProductIdPatchRequest;
    }) => {
      const response =
        await adminProductsApi.v2AdminProductsProductIdPatch(request);

      return response.data;
    },
  });
};

export const usePatchFramedProductMutation = () => {
  return useMutation({
    mutationFn: async (
      request: AdminProductsApiV2AdminProductsProductIdLinkFramedProductPatchRequest,
    ) => {
      const response =
        await adminProductsApi.v2AdminProductsProductIdLinkFramedProductPatch(
          request,
        );

      return response.data;
    },
  });
};

export const useChangeProductInventoryLocationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      request: AdminProductsApiV2AdminProductsProductIdInventoryLocationPutRequest,
    ) => {
      const response =
        await adminProductsApi.v2AdminProductsProductIdInventoryLocationPut(
          request,
        );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      queryClient.invalidateQueries({ queryKey: ["picklistitems"] });
    },
  });
};

export const usePatchProductMediaMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      productId: string;
      mediaContentDtoArray: Array<AdminMediaContentRequest>;
    }) => {
      const response =
        await adminProductsApi.v2AdminProductsProductIdMediaContentPatch({
          productId: request.productId,
          adminMediaContentRequest: request.mediaContentDtoArray,
        });

      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};

export const useUploadProductMediaMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: {
      productId: string;
      files: Array<File>;
      origin: string;
    }) => {
      const response =
        await adminProductsApi.v2AdminProductsProductIdMediaContentPost(
          request,
        );

      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
  });
};
