export const teamV2Routes = [
  {
    path: "/teams-v2",
    name: "TeamsListPageV2",
    component: () => import("../teams-v2/views/TeamsListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },

  {
    path: "/teams-v2/:teamId",
    name: "TeamDetailsPageV2",
    component: () => import("../teams-v2/views/TeamDetailsBase.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
    redirect: { name: "TeamDetailsV2.Details" },
    children: [
      {
        path: "",
        name: "TeamDetailsV2.Details",
        component: () => import("../teams-v2/views/TeamDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
];
