export type ChipRequestedByUidSignalREvent = {
  chipId: string;
  chipUid: string;
  userId: string;
  externalReferenceId: string;
  orderId: string;
};

export function asChipRequestByUidSignalREvent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: any,
): ChipRequestedByUidSignalREvent | null {
  if (event.type !== "ChipRequestedByUid") {
    return null;
  }

  return {
    chipId: event.payload.chipId,
    chipUid: event.payload.chipUid,
    userId: event.payload.userId,
    externalReferenceId: event.payload.externalReferenceId,
    orderId: event.payload.orderId,
  };
}
