export const eventRoutes = [
  {
    path: "/events/:eventId",
    name: "EventDetailsPage",
    component: () => import("./views/EventDetailsPage.vue"),
    // children are used to defined the content of the tabs
    children: [
      {
        path: "",
        name: "EventDetails.Overview",
        component: () => import("./views/EventOverview.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "categories",
        name: "EventDetails.Categories",
        component: () => import("./views/EventCategories.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "finance",
        name: "EventDetails.Finance",
        component: () => import("./views/EventFinanceView.vue"),
        redirect: {
          name: "EventDetails.Finance.Settings",
        },
        meta: {
          auth: ["Admin.Access"],
        },
        children: [
          {
            path: "settings",
            name: "EventDetails.Finance.Settings",
            component: () => import("./views/EventFinanceSettingsView.vue"),
            meta: {
              auth: ["Admin.Access"],
            },
          },
        ],
      },
      {
        path: "marketing",
        name: "EventDetails.Marketing",
        component: () => import("./views/EventMarketingView.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "product-listings",
        name: "EventDetails.ProductListings",
        component: () => import("./views/EventProductListingsView.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "product-settings",
        name: "EventDetails.ProductListingSettings",
        component: () => import("./views/EventProductSettingsView.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "bulk-upload",
        name: "EventDetails.BulkUpload",
        component: () => import("./views/EventBulkUploading.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "reports",
        name: "EventDetails.Reports",
        component: () => import("./views/EventReportsView.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
        redirect: { name: "EventDetails.Reports.List" },
        children: [
          {
            path: "list",
            name: "EventDetails.Reports.List",
            component: () => import("./views/EventReportsListView.vue"),
            meta: {
              auth: ["Admin.Access"],
            },
          },
          {
            path: "latest",
            name: "EventDetails.Reports.LatestView",
            component: () => import("./views/EventReportsLatestReportView.vue"),
            meta: {
              auth: ["Admin.Access"],
            },
          },
          {
            path: "create",
            name: "EventDetails.Reports.Create",
            component: () => import("./views/EventReportsCreateView.vue"),
            meta: {
              auth: ["Admin.Access"],
            },
          },
          {
            path: ":reportId",
            name: "EventDetails.Reports.View",
            component: () => import("./views/EventReportDetailsView.vue"),
            meta: {
              auth: ["Admin.Access"],
            },
          },
          {
            path: "storage/create",
            name: "EventDetails.Reports.Storage.Create",
            component: () => import("./views/EventStorageReportCreateView.vue"),
          },
        ],
      },
      {
        path: "fixture-updates",
        name: "EventDetails.FixtureUpdates",
        component: () => import("./views/EventFixtureUpdatesView.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
  {
    path: "/events/predict",
    name: "EventPredictPage",
    component: () => import("./views/EventPredictPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/events/create",
    name: "EventCreatePage",
    component: () => import("./views/EventCreatePage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/events",
    name: "EventsListPage",
    component: () => import("./views/EventsListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/events/calendar/:type(month|week)",
    name: "EventsCalendarPage",
    component: () => import("./views/EventsListPage.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
  },
];
