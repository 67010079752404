export const contractRoutes = [
  {
    path: "/contracts/:contractId",
    name: "ContractDetailsPage",
    component: () => import("./views/ContractDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
    children: [
      {
        path: "",
        name: "ContractDetailsPage.Details",
        component: () => import("./views/ContractDetails.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "event-schedules",
        name: "ContractDetailsPage.EventSchedules",
        component: () => import("./views/ContractEventSchedulesList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "events",
        name: "ContractDetailsPage.Events",
        component: () => import("./views/ContractEventsList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "financial-reports",
        name: "ContractDetailsPage.FinancialReports",
        component: () => import("./views/ContractFinancialReportsList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
    ],
  },
  {
    path: "/contracts/:contractId/event-schedules/:eventScheduleContractId",
    name: "ContractDetailsPage.EventSchedules.EventScheduleContractDetailsPage",
    component: () => import("./views/EventScheduleContractDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
