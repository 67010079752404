import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

import { adyenMerchantRoutes } from "@/adyenMerchants/routes";
import { athleteRoutes } from "@/athletes/routes";
import { beneficiaryRoutes } from "@/beneficiaries/routes";
import { bidsRoutes } from "@/bids/routes";
import { categoryRoutes } from "@/categories/routes";
import { contractRoutes } from "@/contracts/routes";
import { customerRoutes } from "@/customers/routes";
import { eventRoutes } from "@/events/routes";
import { fabricksRoutes } from "@/fabricks/routes";
import { framingConfigurationRoutes } from "@/framing/configurations/routes";
import { framingFeatureOptionRoutes } from "@/framing/featureOptions/routes";
import { framingOfferRoutes } from "@/framing/offers/routes";
import { framingOrderRoutes } from "@/framing/orders/routes";
import { framingRoutes } from "@/framing/routes";
import { inboundReportRoutes } from "@/inbound/routes";
import { kitsRoutes } from "@/kits/routes";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { leagueRoutes } from "@/leagues/routes";
import { legalOrganisationRoutes } from "@/legalOrganisations/routes";
import { miscRoutes } from "@/misc/routes";
import type { AdminApp } from "@/misc/types";
import { notificationRoutes } from "@/notifications/routes";
import { orderRoutes } from "@/orders/routes";
import { shipmentRoutes } from "@/orders/shipments/routes";
import { parcelTypeRoutes } from "@/parcelTypes/routes";
import { partnerRoutes } from "@/partners/routes";
import { permissionRoutes } from "@/permissions/routes";
import type { Permission } from "@/permissions/types";
import { pickListsRoutes } from "@/pickPackShip/routes";
import { platformRoutes } from "@/platforms/routes";
import { productLabelRoutes } from "@/products/productLabels/routes";
import { productListingRoutes } from "@/products/productListings/routes";
import { productRoutes } from "@/products/products/routes";
import { productTypeRoutes } from "@/products/productTypes/routes";
import { sportsRoutes } from "@/sports/routes";
import { sportsv2Routes } from "@/sports-v2/routes";
import { teamRoutes } from "@/teams/routes";
import { teamV2Routes } from "@/teams-v2/routes";
import { vendorRoutes } from "@/vendors/routes";

type PermissionProtectedRoute = RouteRecordRaw & {
  meta: RouteRecordRaw["meta"] & {
    auth: Permission[] | { roles: Permission[] };
  };
};

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes: [
    ...adyenMerchantRoutes,
    ...athleteRoutes,
    ...beneficiaryRoutes,
    ...bidsRoutes,
    ...categoryRoutes,
    ...contractRoutes,
    ...customerRoutes,
    ...eventRoutes,
    ...fabricksRoutes,
    ...framingConfigurationRoutes,
    ...framingFeatureOptionRoutes,
    ...framingOfferRoutes,
    ...framingOrderRoutes,
    ...framingRoutes,
    ...inboundReportRoutes,
    ...kitsRoutes,
    ...leagueRoutes,
    ...legalOrganisationRoutes,
    ...miscRoutes,
    ...notificationRoutes,
    ...orderRoutes,
    ...parcelTypeRoutes,
    ...partnerRoutes,
    ...permissionRoutes,
    ...pickListsRoutes,
    ...platformRoutes,
    ...productLabelRoutes,
    ...productListingRoutes,
    ...productRoutes,
    ...productTypeRoutes,
    ...shipmentRoutes,
    ...sportsRoutes,
    ...sportsv2Routes,
    ...teamRoutes,
    ...teamV2Routes,
    ...vendorRoutes,
    {
      path: "/:pathMatch(.*)",
      component: () => import("../misc/views/NotFound.vue"), // This should never render, but it gets rid of the router warnings.
    },
  ] as PermissionProtectedRoute[],
});

router.beforeEach((to) => {
  to.meta.layout = to.meta.layout || DefaultLayout;
});

export const routerPlugin = (app: AdminApp) => {
  app.router = router;
  app.use(router);
};
