<script setup lang="ts">
import {
  AdjustmentsHorizontalIcon,
  BanknotesIcon,
  BriefcaseIcon,
  CalendarIcon,
  CpuChipIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  HeartIcon,
  HomeIcon,
  NewspaperIcon,
  PaperAirplaneIcon,
  PhotoIcon,
  RectangleGroupIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  Square3Stack3DIcon,
  SwatchIcon,
  TrophyIcon,
  TruckIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline";
import { computed, type FunctionalComponent } from "vue";

import DropdownVue from "@/common/components/DropdownVue.vue";
import MenuLink from "@/common/components/menu/MenuLink.vue";
import type { NavigationLink } from "@/common/types";
import type { Permission } from "@/permissions/types";
import { useCurrentUser } from "@/users/public/composables";

const strapiAdminUrl = import.meta.env.VITE_STRAPI_ADMIN_URL;

const globalNavigationLinks: NavigationLink[] = [
  {
    link: "/",
    name: "Home",
    icon: HomeIcon,
    permissionsNeeded: ["Admin.Access"],
  },
  {
    link: "/platforms",
    name: "Platforms",
    icon: Square3Stack3DIcon,
    permissionsNeeded: ["Admin.Access"],
  },
];

const mwsNavigationLinks: NavigationLink[] = [
  {
    link: "/customers",
    name: "Customers",
    icon: UsersIcon,
    permissionsNeeded: ["Admin.Access"],
  },
  {
    link: '/events/calendar/week?statuses=["Published","Draft","Confirmed"]',
    name: "Events",
    icon: CalendarIcon,
    permissionsNeeded: ["Admin.Access"],
  },
  {
    link: "/bids",
    name: "Bids",
    icon: BanknotesIcon,
    permissionsNeeded: ["Admin.Access"],
  },
  {
    link: "/beneficiaries",
    name: "Beneficiaries",
    icon: HeartIcon,
    permissionsNeeded: ["Admin.Access"],
  },
  {
    link: "/orders",
    name: "Orders",
    icon: CreditCardIcon,
    permissionsNeeded: ["Admin.Access"],
  },
  {
    link: "/permissions/roles",
    name: "Role Management",
    icon: ShieldCheckIcon,
    permissionsNeeded: ["Developer.Access"],
  },
  {
    link: "/categories",
    name: "Categories",
    icon: SwatchIcon,
    permissionsNeeded: ["Categories.Read"],
  },
  {
    link: strapiAdminUrl,
    name: "Strapi",
    icon: NewspaperIcon,
    permissionsNeeded: ["Articles.Read", "Articles.Write"],
    isExternalLink: true,
  },
  {
    link: "/admin-tools",
    name: "Admin Tools",
    icon: AdjustmentsHorizontalIcon,
    permissionsNeeded: ["Developer.Access"],
  },
  {
    icon: PaperAirplaneIcon,
    link: "/pick-pack-ship",
    name: "Pick, Pack & Ship",
    permissionsNeeded: ["Admin.Access"],
  },
];

const framingNavigationLinks: NavigationLink[] = [
  {
    link: "/framing/orders",
    name: "Orders",
    icon: CreditCardIcon,
    permissionsNeeded: ["FramingAdmin.Access", "FramingOrders.Read"],
  },
  {
    link: "/framing/offers",
    name: "Offers",
    icon: BriefcaseIcon,
    permissionsNeeded: ["FramingAdmin.Access", "FramingOffers.Read"],
  },
  {
    link: "/framing/configuration-templates",
    name: "Configuration Templates",
    icon: RectangleGroupIcon,
    permissionsNeeded: ["FramingAdmin.Access", "ConfigurationTemplates.Read"],
  },
  {
    link: "/framing/passe-partouts",
    name: "Passe-partouts",
    icon: PhotoIcon,
    permissionsNeeded: ["FramingAdmin.Access"],
  },
  {
    link: "/framing/mockup-sets",
    name: "Mockup Sets",
    icon: PhotoIcon,
    permissionsNeeded: ["FramingAdmin.Access"],
  },

  {
    link: "/framing/feature-options",
    name: "Feature Options",
    icon: SwatchIcon,
    permissionsNeeded: ["Developer.Access"],
  },
];

const fabricksNavigationLinks: NavigationLink[] = [
  {
    link: "/fabricks/chips",
    name: "Chips",
    icon: CpuChipIcon,
    permissionsNeeded: ["FabricksAdmin.Access"],
  },
  {
    link: "/fabricks/partners",
    name: "Partners",
    icon: UserGroupIcon,
    permissionsNeeded: ["FabricksAdmin.Access"],
  },
  {
    // trackable products
    link: "/fabricks/trackable-products",
    name: "Trackable Products",
    icon: ShoppingCartIcon,
    permissionsNeeded: ["FabricksAdmin.Access"],
  },
];

const filteredGlobalNavigationLinks = computed(() => {
  return globalNavigationLinks.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const filteredMwsNavigationLinks = computed(() => {
  return mwsNavigationLinks.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const filteredFramingNavigationLinks = computed(() => {
  return framingNavigationLinks.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const filteredFabricksNavigationLinks = computed(() => {
  return fabricksNavigationLinks.filter((navigationLink) =>
    userHasPermission(navigationLink.permissionsNeeded),
  );
});

const subMenuItems: {
  name: string;
  permissionsNeeded?: Permission[];
  icon: FunctionalComponent;
  subItems: {
    link: string;
    name: string;
  }[];
}[] = [
  {
    name: "Product management",
    icon: ShoppingBagIcon,
    subItems: [
      {
        link: "/product-listings",
        name: "Product Listings ",
      },
      {
        link: "/products",
        name: "Products (new)",
      },
      {
        link: "/product-types",
        name: "Types",
      },
      {
        link: "/labels",
        name: "Labels",
      },
    ],
  },
  {
    name: "Finance",
    icon: CurrencyDollarIcon,
    subItems: [
      {
        link: "/partners",
        name: "Partners",
      },
      {
        link: "/vendors",
        name: "Vendors",
      },
      {
        link: "/legal-organisations",
        name: "Legal Organisations",
      },
      {
        link: "/adyen-merchants",
        name: "Adyen Merchants",
      },
    ],
  },
  {
    name: "Shipping",
    icon: TruckIcon,
    subItems: [
      {
        link: "/global-shipping-configs",
        name: "Global Shipping Configs",
      },
      {
        link: "/parcel-types",
        name: "Parcel types",
      },
      {
        link: "/fabricks/chip-scans",
        name: "Find order (NFC)",
      },
    ],
  },
  {
    name: "Sports management",
    icon: TrophyIcon,
    subItems: [
      {
        link: "/sports",
        name: "Sports",
      },
      {
        link: "/teams",
        name: "Teams",
      },
      {
        link: "/athletes",
        name: "Athletes",
      },
      {
        link: "/leagues",
        name: "Leagues",
      },
    ],
  },
  {
    name: "Sports management V2",
    permissionsNeeded: ["Developer.Access"],
    icon: TrophyIcon,
    subItems: [
      {
        link: "/sports-v2",
        name: "Sports",
      },
      {
        link: "/teams-v2",
        name: "Teams",
      },
      {
        link: "/athletes-v2",
        name: "Athletes",
      },
    ],
  },
];

const filteredSubMenuItems = computed(() =>
  subMenuItems.filter((menuItem) =>
    !menuItem.permissionsNeeded
      ? true
      : userHasPermission(menuItem.permissionsNeeded),
  ),
);

const { userHasPermission } = useCurrentUser();
</script>

<template>
  <div
    v-if="filteredGlobalNavigationLinks.length > 0"
    class="sidebar-links__global-section"
  >
    <MenuLink
      v-for="navigationLink in filteredGlobalNavigationLinks"
      :key="navigationLink.name"
      :navigation-link="navigationLink"
    />
  </div>

  <div class="sidebar-links__marketplace-section">
    <span class="sidebar-links__nav-section-title">MatchWornShirt</span>
    <MenuLink
      v-for="navigationLink in filteredMwsNavigationLinks"
      :key="navigationLink.name"
      :navigation-link="navigationLink"
      :data-cy="`vue-navigation-link-${navigationLink.name
        .toLowerCase()
        .replace(' ', '-')}`"
    />
    <DropdownVue
      v-for="subMenuItem in filteredSubMenuItems"
      :key="subMenuItem.name"
      :title="subMenuItem.name"
      :options="subMenuItem.subItems"
      :icon="subMenuItem.icon"
      :data-cy="`vue-navigation-dropdown-${subMenuItem.name
        .toLowerCase()
        .replace(' ', '-')}`"
    />
  </div>

  <div
    v-if="filteredFramingNavigationLinks.length > 0"
    class="sidebar-links__framing-section"
  >
    <span class="sidebar-links__nav-section-title">Frame the game</span>
    <MenuLink
      v-for="navigationLink in filteredFramingNavigationLinks"
      :key="navigationLink.name"
      :navigation-link="navigationLink"
    />
  </div>

  <div
    v-if="filteredFabricksNavigationLinks.length > 0"
    class="sidebar-links__fabricks-section"
  >
    <span class="sidebar-links__nav-section-title">Fabricks</span>
    <MenuLink
      v-for="navigationLink in filteredFabricksNavigationLinks"
      :key="navigationLink.name"
      :navigation-link="navigationLink"
    />
  </div>
</template>

<style setup lang="postcss">
.sidebar-links {
  &__marketplace-section {
    @apply pt-4 space-y-1 border-t border-gray-700;
  }

  &__framing-section {
    @apply pt-4 space-y-1 border-t border-gray-700;
  }

  &__fabricks-section {
    @apply pt-4 space-y-1 border-t border-gray-700;
  }

  &__nav-section-title {
    @apply text-gray-400 px-2 uppercase text-sm;
  }

  &__link {
    @apply text-gray-100 hover:bg-gray-700 hover:text-white flex items-center px-2 py-2 text-sm font-medium rounded-md justify-between;

    &__content {
      @apply flex items-center gap-x-4;

      &__icon {
        @apply text-gray-400 text-2xl;
      }
    }
  }
}
</style>
