import {
  computed,
  type MaybeRef,
  onBeforeUnmount,
  onMounted,
  ref,
  type Ref,
  unref,
  watch,
} from "vue";
import { useToast } from "vue-toastification";

import { useWebSocketsAdminHub } from "@/common/composables/useWebSockets";
import { getErrorMessage } from "@/common/utils/getErrorMessage";
import {
  asChipRequestByUidSignalREvent,
  type ChipRequestedByUidSignalREvent,
} from "@/common/utils/signalREventMapper";

export const useOrderPageScanning = () => {
  const toast = useToast();
  const chipScannedEvent = useRealTimeScanning();

  const scannedOrderId = ref<string | undefined>(undefined);

  watch(
    chipScannedEvent,
    () => {
      if (chipScannedEvent.value?.orderId === null) {
        toast.warning("No order", { timeout: 5000 });

        return;
      }

      scannedOrderId.value = chipScannedEvent.value?.orderId;
    },
    { immediate: false },
  );

  return scannedOrderId;
};

type Params = {
  /**
   * Whether the real-time scanning should be active or not.
   * If not provided, it will be active by default.
   */
  isActive?: MaybeRef<boolean>;
};

export const useRealTimeScanning = (params?: Params) => {
  const toast = useToast();
  const chipScannedEvent: Ref<ChipRequestedByUidSignalREvent | null> =
    ref(null);

  try {
    const connection = useWebSocketsAdminHub();

    connection.on("all", (ev) => {
      chipScannedEvent.value = asChipRequestByUidSignalREvent(ev);
    });

    const startConnection = async () => {
      try {
        await connection.start();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error connecting to real-time updates", error);
        toast.error(
          `Error connecting to real-time updates: ${getErrorMessage(error)[0]}`,
        );
      }
    };

    const shouldConnect = computed(() => {
      return params?.isActive === undefined ? true : unref(params?.isActive);
    });

    onMounted(() => {
      if (shouldConnect.value) {
        startConnection();
      }
    });

    watch(shouldConnect, (newValue) => {
      if (newValue) {
        startConnection();
      } else {
        connection.stop();
      }
    });

    onBeforeUnmount(() => {
      connection.stop();
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return chipScannedEvent;
};
