<script setup lang="ts">
import type { AdminListPlatform } from "mws-platform-ts-sdk";
import { computed, watch } from "vue";

import DropdownVue from "@/common/components/DropdownVue.vue";
import type { Guid } from "@/common/types";
import { PLATFORM_STORAGE_KEY } from "@/common/utils/constants";
import { useGetPlatformsQuery } from "@/platforms/api/queries";
import { usePlatformStore } from "@/platforms/store";

const platformStore = usePlatformStore();
const { data: platforms } = useGetPlatformsQuery();

const findCurrentPlatform = (
  platforms: AdminListPlatform[] | undefined,
  platformKey: Guid,
) => {
  return platforms?.find((platform) => platform.id === platformKey);
};

const platformOptions = computed(() => {
  return platforms.value?.results.map((platform) => ({
    name: platform.name || "Unnamed platform",
    value: platform.id,
  }));
});

const currentPlatform = computed(() => {
  return findCurrentPlatform(
    platforms.value?.results,
    platformStore.platformKey,
  );
});

watch(platforms, () => {
  // if we have successfully fetched the platforms and we cannot find the current platform
  // then we should clear the local storage and reload

  if (
    platforms.value &&
    !findCurrentPlatform(platforms.value.results, platformStore.platformKey)
  ) {
    localStorage.removeItem(PLATFORM_STORAGE_KEY);
    window.location.reload();
  }
});

const switchPlatform = (platformKey?: string) => {
  if (!platformKey) return;

  if (platformKey === platformStore.platformKey) {
    return;
  }

  platformStore.setPlatformKey(platformKey);
  // TODO this is done to ensure that all data is fetched with the proper platform id
  // we can probably rework this via query invalidation, but it will require a fair bit
  // of testing to validate and would be best done after more SDk and tanstack-query adoption
  window.location.reload();
};
</script>

<template>
  <DropdownVue
    v-if="currentPlatform"
    dropdown-type="floating"
    :title="currentPlatform.name || 'Unnamed platform'"
    :options="platformOptions ?? []"
    option-type="button"
    theme="light"
    @item-click="switchPlatform"
  />
</template>
