<script setup lang="ts">
import debounce from "lodash/debounce";
import { ref, toRefs, watch } from "vue";

import type { ChipRequestedByUidSignalREvent } from "@/common/utils/signalREventMapper";
import ProductListingCard from "@/products/productListings/components/ProductListingCard.vue";

const props = defineProps<{
  chipScannedEvent: ChipRequestedByUidSignalREvent | null;
}>();
const { chipScannedEvent } = toRefs(props);

defineEmits<{
  (e: "link-clicked"): void;
}>();

const productListingId = ref<string | undefined>(undefined);

const debouncedChipScannedCallback = debounce(async () => {
  productListingId.value = chipScannedEvent.value?.externalReferenceId;
}, 500);

watch(chipScannedEvent, debouncedChipScannedCallback, { immediate: false });
</script>

<template>
  <div class="size-full">
    <Transition name="fade">
      <ProductListingCard
        v-if="productListingId"
        :product-listing-id="productListingId"
        :open-link-in-new-tab="false"
        :show-cross="false"
        inner-class="justify-center h-28"
        @link-clicked="$emit('link-clicked')"
      />
    </Transition>
  </div>
</template>

<style scoped lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
