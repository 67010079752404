<script setup lang="ts">
import { Bars3BottomLeftIcon, UserIcon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { useMagicKeys, whenever } from "@vueuse/core";
import { useAuth } from "@websanova/vue-auth";
import { computed } from "vue";

import PlatformPicker from "@/platforms/components/PlatformPicker.vue";
import { useCurrentUser } from "@/users/public/composables";

const emit = defineEmits<{
  (e: "open-sidebar-click"): void;
  (e: "open-search-click"): void;
}>();

const { userHasPermission, logout } = useCurrentUser();

// if OS is mac, short cut is CMD + K, otherwise CTRL + K
const isMac = computed(
  () => navigator.userAgent.toLowerCase().indexOf("mac") > 0,
);

const searchTextIndicator = computed(() => {
  if (isMac.value) {
    return "CMD+K";
  }

  return "CTRL+K";
});

const keys = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.type !== "keydown") return;

    if (e.key !== "k") return;

    if ((isMac.value && e.metaKey) || (!isMac.value && e.ctrlKey)) {
      e.preventDefault();

      return;
    }
  },
});

const cmdK = keys["Cmd+K"];
const ctrlK = keys["Ctrl+K"];
const shortCutTriggered = computed(() =>
  isMac.value ? cmdK?.value : ctrlK?.value,
);
whenever(shortCutTriggered, () => {
  emit("open-search-click");
});

const auth = useAuth();
const user = auth.user();
</script>

<template>
  <div class="menu-topbar__container">
    <button
      type="button"
      class="menu-topbar__open-sidebar-button"
      @click="$emit('open-sidebar-click')"
    >
      <span class="sr-only">Open sidebar</span>
      <Bars3BottomLeftIcon
        class="menu-topbar__menu-icon"
        aria-hidden="true"
      />
    </button>

    <div class="menu-topbar__right-side">
      <div class="menu-topbar__right-side__content">
        <div class="menu-topbar__right-side__search-container">
          <button
            class="menu-topbar__right-side__search__button group"
            @click="$emit('open-search-click')"
          >
            <MagnifyingGlassIcon
              class="menu-topbar__right-side__search__icon"
              aria-hidden="true"
            />
            <span class="menu-topbar__right-side__search__text">Search...</span>

            <span class="menu-topbar__right-side__search__indicator">
              {{ searchTextIndicator }}
            </span>
          </button>
        </div>
        <div class="menu-topbar__utilities">
          <PlatformPicker v-if="userHasPermission('GlobalUser.Access')" />
          <div
            v-if="user"
            class="hidden md:flex px-4 border-x whitespace-nowrap"
          >
            <UserIcon class="size-5 mr-1 mt-0.5" />
            <span>
              {{ user.email || "unknown user" }}
            </span>
          </div>
          <button
            class="menu-topbar__utilities__logout-button"
            @click="logout"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.menu-topbar {
  &__container {
    @apply sticky top-0 z-30 flex-shrink-0 flex h-16 bg-white shadow;
  }

  &__open-sidebar-button {
    @apply border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500;
    @apply px-4 border-r;
    @apply md:hidden;
  }

  &__menu-icon {
    @apply h-6 w-6;
  }

  &__right-side {
    @apply flex-1 flex justify-between;
    @apply px-2 sm:px-6 md:px-8;

    &__content {
      @apply flex-1 flex w-full justify-between;
    }

    &__form {
      @apply w-full flex md:ml-0;
    }

    &__search-container {
      @apply relative h-full flex items-center;
    }

    &__search {
      &__button {
        @apply text-gray-500 bg-gray-100;
        @apply hover:bg-gray-200 hover:text-black;
        @apply relative py-2 px-4 rounded-lg flex items-center gap-x-2;
      }

      &__icon {
        @apply h-5 w-5;
      }

      &__text {
        @apply hidden md:block;
        @apply text-base text-gray-500 group-hover:text-gray-800;
      }

      &__indicator {
        @apply hidden md:block;
        @apply text-sm text-gray-400 border rounded border-gray-300 ml-2 px-2 group-hover:text-gray-500;
      }
    }
  }

  &__utilities {
    @apply h-full flex items-center gap-x-2 md:gap-x-4;
    &__logout-button {
      @apply text-sm;
      @apply text-indigo-600 bg-indigo-100 bg-opacity-50;
      @apply hover:bg-indigo-200 hover:bg-opacity-50;
      @apply relative py-2 px-4 rounded-lg flex items-center gap-x-2;
    }
  }
}
</style>
